import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ModalComponent } from 'ng-modal-lib';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  chatDriverList: any = [];
  private subscription: Subscription = new Subscription;
  private unsubscribe$ = new Subject<void>();


  constructor(
    public CallMethod: CallmethodService,
    public shareData: DataShareService,
    public Global: GlobalService,
  ) {


  }

  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {

        if (data.function == 'chat') {
          this.chatModelShow();
          this.Global.setLocalStorage({ 'key': 'chatBadgeCount', 'value': 0 });
        }
        else if (data.function == 'allDriverForChat') {
          let allDriver = data.driver;

          let localStorageChatAllDriver = this.Global.getLocalStorage({ 'key': 'chatAllDriverList' });
          let chatDrivers = [];

          for (let i = 0; i < allDriver.length; i++) {

            if (localStorageChatAllDriver != undefined && localStorageChatAllDriver.length > 0) {
              let objIndex = localStorageChatAllDriver.findIndex(((obj: any) => obj.driverId == allDriver[i].driverId));
              chatDrivers.push({
                'driverId': allDriver[i].driverId,
                'driverName': allDriver[i].driverName,
                'lastMessageTime': objIndex != -1 ? localStorageChatAllDriver[objIndex].lastMessageTime : '',
                'unSeenMessageCount': objIndex != -1 ? localStorageChatAllDriver[objIndex].unSeenMessageCount : 0,
                'driverPhone': allDriver[i].driverPhone,
                'driverStatus': allDriver[i].driverStatus,
                'freeTime': allDriver[i].freeTime
              });
            } else {
              chatDrivers.push({
                'driverId': allDriver[i].driverId,
                'driverName': allDriver[i].driverName,
                'driverPhone': allDriver[i].driverPhone,
                'lastMessageTime': '',
                'unSeenMessageCount': 0,
                'driverStatus': allDriver[i].driverStatus,
                'freeTime': allDriver[i].freeTime,
              });
            }
          }

          let driverList = this.Global.sortArrayOfObject(chatDrivers, 'freeTime', 'desc');
          this.chatDriverList = this.Global.sortArrayOfObject(driverList, 'lastMessageTime', 'desc');
          this.Global.setLocalStorage({ 'key': 'chatAllDriverList', 'value': this.chatDriverList });
        }
        else if (data.function == 'mqttShareToChat') {
          let opts = data.mqttData;
          if (opts.statusFlag == 51 || opts.statusFlag == 54) {
            if (this.showChatModel == true) {
              if ((opts.statusFlag == 51 && opts.dispatcherId != this.Global.dispatcherDetails.dispatcherId) || opts.statusFlag == 54) {
                if (this.chatBetween.driverId == opts.driverId) {
                  let chatArrObj: any = {
                    'sendBy': opts.sendBy,
                    'message': opts.message,
                    'time': moment(opts.time, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat)
                  }
                  if (opts.statusFlag == 51) {
                    // let dispatcherNameSplitArray =  opts.dispatcherName.split(' ');
                    let dispatcherNameSplitArray = opts.dispatcherName != undefined ? opts.dispatcherName.split(' ') : this.Global.dispatcherDetails.dispatcherName.split(' ');
                    chatArrObj['dispatcherNameTwoChar'] = dispatcherNameSplitArray.length > 1 ? dispatcherNameSplitArray[0].charAt(0) + dispatcherNameSplitArray[dispatcherNameSplitArray.length - 1].charAt(0) : dispatcherNameSplitArray[0].charAt(0)
                  }


                  this.chatArr.push(chatArrObj);
                  this.setMessageCountAndLastMessageTime({ 'driverId': opts.driverId, 'time': opts.time, 'statusFlag': opts.statusFlag }, true)
                  this.chatStickToBottom();
                } else {

                  this.setMessageCountAndLastMessageTime({ 'driverId': opts.driverId, 'time': opts.time, 'statusFlag': opts.statusFlag }, false)
                }
              }
            } else {
              let chatMessageCount = this.Global.getLocalStorage({ 'key': 'chatBadgeCount' });
              chatMessageCount = chatMessageCount == undefined ? 1 : chatMessageCount + 1;
              this.Global.setLocalStorage({ 'key': 'chatBadgeCount', 'value': chatMessageCount });
              this.shareData.shareData({ 'function': 'chatBadgeCount' });
              this.setMessageCountAndLastMessageTime({ 'driverId': opts.driverId, 'time': opts.time, 'statusFlag': opts.statusFlag }, false);
            }
          }
        }
        else if (data.function == 'changeThemeColor') {
          this.chat.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.chat.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.chat.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;
        }
        else if (data.function == 'openDriverChat') {
          console.log("data::::::::openDriverChat::::::", data.data);
          let selectedDriverChat = data.data
          console.log("selectedDriverChat :::::::", selectedDriverChat);

          let req = {
            driverId: selectedDriverChat.driverId,
            driverName: selectedDriverChat.driverName,
            driverPhone: selectedDriverChat.mobile,
            driverStatus: selectedDriverChat.driverStatus,
            lastMessageTime: selectedDriverChat.lastMessageTime,
            unSeenMessageCount: selectedDriverChat.unSeenMessageCount
          }
          this.Global.setLocalStorage({ 'key': 'chatBadgeCount', 'value': 0 });
          this.getChat(req, 1);
          this.chatModelShow();
        }
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showChatModel: boolean = false;
  @ViewChild("chat") public chat!: ModalComponent;
  @ViewChild("scrollToTop") public scrollChat!: ElementRef;

  chatModelShow(searchValue: string = '') {
    this.chat.modalRoot.nativeElement.style.zIndex = this.chat.getMaxModalIndex() + 2;
    if (this.showChatModel == false) {
      setTimeout(() => {
        this.chat.modalRoot.nativeElement.style.top = environment.windowPopupStyle.chat.top;
      }, 0);

      if (this.chat.modalBody.nativeElement.style.height.trim() == '') {
        this.chat.modalBody.nativeElement.style.height = environment.windowPopupStyle.chat.height;
        let width = parseInt(environment.windowPopupStyle.chat.width.replace('px', ''));
        if (window.innerWidth < parseInt(environment.windowPopupStyle.chat.width.replace('px', ''))) {
          width = window.innerWidth - 2;
        }
        this.chat.modalRoot.nativeElement.style.width = width.toString() + 'px';
      }

      this.chat.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.chat.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.chat.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;

      this.chat.show();
      let dispatcherNameSplitArray = this.Global.dispatcherDetails.dispatcherName.split(' ');
      this.chatBetween.dispatcherNameTwoChar = dispatcherNameSplitArray.length > 1 ? dispatcherNameSplitArray[0].charAt(0) + dispatcherNameSplitArray[dispatcherNameSplitArray.length - 1].charAt(0) : dispatcherNameSplitArray[0].charAt(0)
      this.showChatModel = true;
    }
  }

  closechatModel() {
    this.showChatModel = false;
    // this.chatBetween = {}
  }

  isOpenChatTab: number = 0;
  selectedTabChat(event: any) {
    this.isOpenChatTab = event.index;
    if (this.isOpenChatTab == 1) {
      this.getDispatcher();
    }
  }

  chatDispatcherList: any = [];
  getDispatcher() {
    this.CallMethod.getAllDispatcher({}).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      if (result.status) {
        this.chatDispatcherList = result.data;
      }
    });
  }

  chatArr: any = [];
  chatBetween: any = {};
  showDriverDetailOnChat: boolean = false;
  currentPage: number = 1;
  isAllChatLoaded: boolean = false;
  chatShimmer: boolean = false;
  chatLimit: number = 100;
  getChat(driver: any, currentPage: number) {
    console.log(" ::::::::::: driver::::::", driver);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('messageInput'))?.focus();
      }, 10);

      this.chatShimmer = true;
      let chatOffset = (currentPage == 1) ? 0 : ((currentPage * this.chatLimit) - this.chatLimit);
      chatOffset == 0 ? this.chatArr = [] : this.chatArr;
      this.currentPage = currentPage;
      this.isAllChatLoaded = false;
      this.chatBetween = {};

      let requestObj = {
        'senderId': driver.driverId,
        'senderRole': 2,
        'limit': this.chatLimit,
        'offset': chatOffset,
        'isGetChatBetweenDispatcher': false
      }

      let driverNameSplitArray = driver.driverNameTwoChar == undefined ? driver.driverName.trim().split(' ') : driver.driverNameTwoChar;
      let dispatcherNameSplitArray = driver.dispatcherNameTwoChar == undefined ? this.Global.dispatcherDetails.dispatcherName.split(' ') : driver.dispatcherNameTwoChar;

      this.chatBetween = {
        'driver': driver.driverName,
        'driverId': driver.driverId,
        'driverPhone': driver.driverPhone,
        'dispatcher': this.Global.dispatcherDetails.dispatcherName,
        'dispatcherId': this.Global.dispatcherDetails.dispatcherId,
        'driverNameTwoChar': driverNameSplitArray.length > 1 ? driverNameSplitArray[0].charAt(0) + driverNameSplitArray[driverNameSplitArray.length - 1].charAt(0) : driverNameSplitArray[0].charAt(0),
        'dispatcherNameTwoChar': dispatcherNameSplitArray.length > 1 ? dispatcherNameSplitArray[0].charAt(0) + dispatcherNameSplitArray[dispatcherNameSplitArray.length - 1].charAt(0) : dispatcherNameSplitArray[0].charAt(0),
        'lastMessageTime': driver.lastMessageTime,
        'unSeenMessageCount': driver.unSeenMessageCount,
        'driverStatus': driver.driverStatus
      };

      this.showDriverDetailOnChat = true;
      this.CallMethod.getDriverChat(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        if (result.status) {
          chatOffset == 0 ? this.chatArr = [] : this.chatArr;
          let chat = result.data;
          let beforeChatArr = this.chatArr.length;
          for (let i = 0; i < chat.length; i++) {
            let dispatcherNameSplitArray = chat[i].dispatcherName != undefined ? chat[i].dispatcherName.split(' ') : this.Global.dispatcherDetails.dispatcherName.split(' ');
            this.chatArr.unshift({
              'sendBy': chat[i].sendBy,
              'message': chat[i].message,
              'time': moment(chat[i].created_at_company_time, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
              'dispatcherNameTwoChar': dispatcherNameSplitArray.length > 1 ? dispatcherNameSplitArray[0].charAt(0) + dispatcherNameSplitArray[dispatcherNameSplitArray.length - 1].charAt(0) : dispatcherNameSplitArray[0].charAt(0)
            });
          }
          if (beforeChatArr != this.chatArr.length) {
            let objIndex = this.chatDriverList.findIndex(((obj: any) => obj.driverId == driver.driverId));

            if (objIndex != -1) {
              this.chatDriverList[objIndex].unSeenMessageCount = 0;
              this.chatDriverList = this.Global.sortArrayOfObject(this.chatDriverList, 'lastMessageTime', 'desc');
              this.Global.setLocalStorage({ 'key': 'chatAllDriverList', 'value': this.chatDriverList });

              if (this.searchDriverListArray.length != 0) {
                let searchArrayIndex = this.searchDriverListArray.findIndex(((obj: any) => obj.driverId == driver.driverId));

                if (searchArrayIndex != -1) {
                  this.searchDriverListArray[searchArrayIndex].unSeenMessageCount = 0;
                }
              }
            }
            if (chatOffset == 0) {
              this.chatStickToBottom();
            }
          } else {
            this.isAllChatLoaded = true;
          }
          resolve(true);
        }
        this.chatShimmer = false;
      });

      resolve(false);
    });
  }

  getChatDispatcher(dispatcher: any) {
    let requestObj = {
      'senderId': dispatcher.dispatcherId,
      'isGetChatDispatcher': true,
      'senderRole': 3,
      'limit': 10,
      'offset': 0
    }

    this.CallMethod.getDriverChat(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      if (result.status) {
        let chat = result.data;
      }
    });
  }

  sendMessageButtonDisable: boolean = false;
  sendMessageToDriver(chatForm: any) {
    let data = chatForm.value;
    chatForm.resetForm();
    if (data.message != undefined && data.message.trim() != "" && this.chatBetween.driverId != undefined) {
      this.sendMessageButtonDisable = true;
      let requestObj = {
        "dispatcherId": this.Global.dispatcherDetails.dispatcherId,
        "dispatcherName": this.Global.dispatcherDetails.dispatcherName,
        "driverName": this.chatBetween.driver,
        "driverId": this.chatBetween.driverId,
        "message": data.message.trim(),
        "companyId": this.Global.companyDetails.companyId
      }
      this.CallMethod.sendMessageToDriver(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        if (result.status) {
          let dispatcherNameSplitArray = this.Global.dispatcherDetails.dispatcherName.split(' ');
          let companyTime = this.Global.getCompanyCurrentTime();
          this.chatArr.push({
            'sendBy': 3,
            'message': data.message,
            'time': companyTime,
            'dispatcherNameTwoChar': dispatcherNameSplitArray.length > 1 ? dispatcherNameSplitArray[0].charAt(0) + dispatcherNameSplitArray[dispatcherNameSplitArray.length - 1].charAt(0) : dispatcherNameSplitArray[0].charAt(0)
          });
          this.setMessageCountAndLastMessageTime({ 'driverId': this.chatBetween.driverId, 'time': moment(companyTime, this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss') }, true)
          this.chatStickToBottom();

        }
        this.sendMessageButtonDisable = false;
        (<HTMLInputElement>document.getElementById('messageInput'))?.focus();
      });
    }
  }


  sendMessageToDispatcher(chatForm: any) {

    let requestObj = {
      'senderDispatcherId': this.Global.dispatcherDetails.dispatcherId,
      'senderDispatcherName': this.Global.dispatcherDetails.dispatcherName,
      'receiverDispatcherId': 45,
      'receiverDispatcherName': 'Kartavya',
      'message': chatForm.value.message
    }
    this.CallMethod.sendMessageToDispatcher(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {

      if (result.status) {

      }
    })

  }

  onScroll() {
    let element = this.scrollChat.nativeElement

    let atTop = element.scrollTop <= 0;

    if (atTop) {
      let req = {
        driverId: this.chatBetween.driverId,
        driverName: this.chatBetween.driver,
        driverPhone: this.chatBetween.driverPhone,
        driverStatus: this.chatBetween.driverStatus,
        lastMessageTime: this.chatBetween.lastMessageTime,
        unSeenMessageCount: this.chatBetween.unSeenMessageCount
      }

      if (this.isAllChatLoaded === false) {
        this.currentPage = this.currentPage + 1;
        this.getChat(req, this.currentPage).then((res) => {
          setTimeout(() => {
            element.scrollTop = element.clientHeight;
          }, 1000);
        });
      }
    }
  }

  searchDriverListArray: any = [];
  searchDriver(event: any) {
    let searchedDriver = event.target.value;

    if (searchedDriver != "" && searchedDriver != null && searchedDriver != undefined) {
      this.searchDriverListArray = this.Global.filterByValueObj(this.chatDriverList, "driverName", searchedDriver);
    } else {
      this.searchDriverListArray = [];
    }
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------

  setMessageCountAndLastMessageTime(opts: any, isMessageSeen: boolean) {
    let objIndex = this.chatDriverList.findIndex(((obj: any) => obj.driverId == opts.driverId));

    if (objIndex != -1) {
      this.chatDriverList[objIndex].lastMessageTime = moment(opts.time, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat);
      if (!isMessageSeen) {
        this.chatDriverList[objIndex].unSeenMessageCount = this.chatDriverList[objIndex].unSeenMessageCount + 1;
      }
      this.chatDriverList = this.Global.sortArrayOfObject(this.chatDriverList, 'lastMessageTime', 'desc');
      this.Global.setLocalStorage({ 'key': 'chatAllDriverList', 'value': this.chatDriverList });
    }
  }

  chatStickToBottom() {
    setTimeout(() => {
      let element = this.scrollChat.nativeElement;
      element.scrollTop = element.scrollHeight;
    }, 0);
  }

}
